import jwt_decode from "jwt-decode";
import { getCookie } from "../Common/getCookie";

export function checkIfAuthenticated() {
    try {
      var jwt = getCookie("jwt");
      var Role = 0;  
      var decoded = jwt_decode(jwt);
      Role = decoded.Role;
      //Role = 0 Inaktiv
      if (Role!==0) {
        return true
      }
      else{
        return false
      }
    } catch (e) {
      return false
    }
  }
  
